<template>
  <div class="modal-overlay">    
    <div class="modal-content no-print" id="restriction">
      <div class="source">
        <template v-for="n in itemCount">
          <img  :key="`item${n}`" class="item" alt="box texture" draggable="true" :src="require(`@/assets/textures/items/box_${getRndInt(0,5)}.png`)">
        </template>
      </div>
      <div class="target" droppable="true">
        <div id="obstacle-tool-holder">
          <img :src="require(`@/assets/textures/items/use_item_${tool_seed}.png`)" draggable="false">
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import interact from 'interactjs';

export default {
  name: 'ObstacleModal',
  data() {
    return {
      items: [],
      dragged: null,
      droppedItems: 0,
      tool_seed: 0,
    }
  },
  props: ['obstacleModal', 'itemCount'],
  methods: {
    placeItemsRandomly() {
      let box = document.querySelector(".source").getBoundingClientRect();
      this.items = document.querySelectorAll('.item');

      const element = document.querySelector('.item');
      const style = getComputedStyle(element);
      const itemWidth = parseInt(style.getPropertyValue('width'));
      const itemHeight = parseInt(style.getPropertyValue('height'));

      this.droppedItems = 0;
      for (let item of this.items) {
        // reset changes applied to items in previous obstacle modal
        item.style.display = 'block';
        item.classList.remove('dropped');
        item.style.transform = 'translate(0px, 0px)';
        item.removeAttribute('data-x');
        item.removeAttribute('data-y');
        
        //generate new positions within source div
        item.style.left = this.generateRandomNum(0, box.width - itemWidth) + 'px';
        item.style.top = this.generateRandomNum(0, box.height - itemHeight) + 'px';
      }

      // genereate tool's random seed
      this.tool_seed = this.getRndInt(0,5)

    },
    generateRandomNum(min, max) {
      return Math.random() * (max - min) + min;
    },
    getRndInt(min, max){
      return Math.floor(this.generateRandomNum(min, max))
    },
    dragMoveListener(event) {
      var target = event.target;
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

      target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    },
  },
  watch: {
    'obstacleModal': {
      handler(newValue) {
        if (newValue) {
          this.placeItemsRandomly();
        }
      }
    }
  },
  mounted() {
    interact('.item').draggable({
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: document.getElementById('restriction'),
          endOnly: true
        })
      ],
      autoScroll: false,

      listeners: {
        move: this.dragMoveListener,
      }
    });

    const thisRef = this;

    interact('.target').dropzone({
      ondrop: function (event) {
        event.relatedTarget.style.display = 'none';
        event.relatedTarget.classList.add('dropped');
        
        let items = document.querySelectorAll('.item');
        thisRef.droppedItems = 0;
        for (let item of items) {
          if (item.classList.contains('dropped')) {
            thisRef.droppedItems++;
          }
        }
        if (thisRef.droppedItems === thisRef.itemCount) {
          thisRef.$emit('toggleObstacleModal');
        }
      }
    })
  },
}
</script>
  
<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 2rem;
  max-width: 600px;
  height: 90vh;
}

.source {
  width: 100%;
  height: 70%;
  margin: auto;
  position: relative;
}

.target {
  width: 80%;
  height: 25%;
  border-radius: 1.5rem;
  border: 4px dashed var(--color-text-primary);
  background-color: rgba(23, 21, 26, 0.76);
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: static;
}

.item {
  transform: translate(0px, 0px);
  border-style: solid;
  width: 42%;
  height: auto;
  aspect-ratio: 1;
  border: none;
  position: absolute;
  -ms-touch-action: none;
  touch-action: none;
  border-radius: 10%;
  box-shadow: 0 0 .5rem var(--color-text-primary-glare),
              inset 0 0 .5rem var(--color-text-primary-glare);
}

#obstacle-tool-holder {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
}
#obstacle-tool-holder img {
  height: 100%;
  width: auto;
  padding: 1.2rem;
}
</style>