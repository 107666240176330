<template>
  <div class="game-wrapper">
    <div class="game-content-holder no-print">
      <nav class="game-nav-wrapper">
        <div>
          <button type="button" class="btn-g btn-m-secondary" @click="showMenu">Menu</button>
          <button type="button" class="btn-g btn-m-primary" @click="requestHint">Hint</button>
          <button type="button" class="btn-g btn-m-secondary" @click="toggleModal('info')">Info</button>
        </div>
      </nav>
      <GameContent
        :loadLevel="loadNextLevel"
        :showHint="showHint"
        :swipeFlag="swipeFlag"
        :activeKeysFlag="activeKeysFlag"
        :obstacleModal="obstacleModal"
        @finishLevel="toggleModal('win')"
        @toggleObstacleModal="toggleObstacleModal"
        @toggleSwiping="toggleSwiping"
        @toggleActiveKeys="toggleActiveKeys" />
    </div>
    <div class="menu-logo-holder only-at-print">
      <img alt="Vue logo" src="@/assets/textures/graphics/logo.png">
    </div>
    <h1 class="only-at-print">Maze map is not printable.</h1>
    <ModalLayer 
      v-show="modal"
      :type="modalType"
      @closeModal="toggleModal"
      @exitToMenu="showMenu"
      @loadNextLevel="sendLoadRequest"/>
    <ObstacleModal
      v-show="obstacleModal"
      :obstacleModal="obstacleModal"
      :itemCount="3"
      @toggleObstacleModal="toggleObstacleModal"/>
  </div>
</template>

<script>
import GameContent from './GameContent.vue';
import ModalLayer from './ModalLayer.vue';
import ObstacleModal from './ObstacleModal.vue';

export default {
  name: 'AppGame',
  components: {
    GameContent,
    ModalLayer,
    ObstacleModal,
},
  data() {
    return {
      modal: 0,
      modalType: "",
      loadNextLevel: false,
      obstacleModal: 0,
      showHint: false,
      swipeFlag: true,
      activeKeysFlag: true,
    }
  },
  methods: {
    showMenu() {
      this.$emit('switchMenuGame');
    },
    toggleModal(type) {
      this.modalType = type;
      this.modal = !this.modal;
    },
    sendLoadRequest() {
      this.loadNextLevel = true

      setTimeout(() => {
        this.loadNextLevel = false
      }, 200);
    },
    toggleObstacleModal() {
      this.obstacleModal = !this.obstacleModal;
    },
    toggleSwiping() {
      this.swipeFlag = !this.swipeFlag;
    },
    toggleActiveKeys() {
      this.activeKeysFlag = !this.activeKeysFlag;
    },
    requestHint() {
      this.showHint = true
      setTimeout(() => {this.showHint = false}, Number(process.env.VUE_APP_HINT_DURATION))
    }
  }
}
</script>

<style>
.game-nav-wrapper{
  width: 100%;
  position: relative;
  z-index: 30;
}
.game-nav-wrapper>div{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>