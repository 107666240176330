<template>
  <div class="menu-wrapper">
    <div class="menu-content-holder">

      <div class="menu-logo-holder only-at-print">
        <img alt="Vue logo" src="@/assets/textures/graphics/logo.png">
        <h1 class="only-at-print">Menu page in not printable.</h1>
      </div>

      <div class="menu-logo-holder no-print">
        <img v-on:dblclick="deleteProgress" alt="Vue logo" src="@/assets/textures/graphics/logo.png">
      </div>
      <div class="no-print">
        <button type="button" class="btn-m btn-m-primary" @click="playButtonClick">{{ playBtnText }}</button>
        <p class="btn-note">{{ nextLevelText }}</p>
      </div>
      <div class="no-print">
        <button type="button" class="btn-m btn-m-secondary" @click="openInfoModal">Game Info</button>
        <p class="btn-note note-authors">2022 HKubo Mišo</p>
      </div>     

    </div>
    <ModalLayer 
      v-show="modal"
      :type="modalType"
      @closeModal="toggleModal" 
      @runGame="playButtonClick"/>
  </div>
</template>

<script>
import ModalLayer from './ModalLayer.vue';

export default {
    name: 'AppMenu',
    components: {
      ModalLayer,
    },
    data() {
      return {
        modal: 0,
        modalType: "info",
        playBtnText: '',
        nextLevelText: '',
      }
    },
    methods: {
      playButtonClick () {
        if (this.gameFinished()) {
          this.modalType = 'levels'
          this.toggleModal()
        }
        else {
          this.$emit('switchMenuGame');
        }
      },
      openInfoModal() {
        this.modalType = 'info'
        this.toggleModal()
      },
      toggleModal() {
        this.modal = !this.modal;
        this.playBtnText = this.getPlaybuttonText()
        this.nextLevelText = this.getNextlevelText()
      },
      deleteProgress() {
        localStorage.removeItem('inited')
      },
      getPlaybuttonText() {
        let isInited = localStorage.getItem('inited')
        let wasPlayed = (JSON.parse(localStorage.getItem('levelsProgress'))).intro != 0

        if (this.gameFinished()) {
          return 'Show All Levels'
        }

        return (isInited && wasPlayed) ? 'Continue' : 'Start'
      },
      getNextlevelText() {
        let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
        let orderLog = JSON.parse(localStorage.getItem('levelsOrder'))
        let levelTypes = Object.keys(progressLog)

        for(let type in levelTypes){
          for(let level in progressLog[levelTypes[type]]){
            
            // find first not-finished / not-played game
            if(progressLog[levelTypes[type]][level] !== 2){
              return `${levelTypes[type]} level #${Number(orderLog[levelTypes[type]][level])+1} (${Number(level)+1}/${progressLog[levelTypes[type]].length})`
            }
          }
        }
        
        return "Thank you for playing!"
      },
      gameFinished() {
        let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
        let levelTypes = Object.keys(progressLog)

        for(let type in levelTypes){
          for(let level in progressLog[levelTypes[type]]){
            if(progressLog[levelTypes[type]][level] !== 2){
              return false
            }
          }
        }

        return true
      },
    },
    mounted() {
      this.playBtnText = this.getPlaybuttonText()
      this.nextLevelText = this.getNextlevelText()
    }
}
</script>

<style>

.menu-content-holder {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.menu-content-holder>* {
  width: 100%;
}

.menu-logo-holder>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: min(15rem, 30vh);
  margin-top: min(1rem, 2vh);
}

.menu-content-holder>div:last-child {
  padding-bottom: 7rem;
}

.btn-note {
  margin-top: .5rem;
  color: var(--color-text-primary);
}

.note-authors {
  opacity: .3;
}
</style>