import sensorService from '@/logic/sensor-service'

export default {
    extends: sensorService,
    methods: {
        moveIfPossible(direction) {
            const mapIndex = this.player.position.y * this.level.gridSize[1] + (this.player.position.x);

            if(!this.activeKeysFlag && this.obstacleModal){
                return;
            }

            switch (direction) {
                case 'up':
                    this.player.heading = 0;
                    if (!(this.level.map[mapIndex] & 1)) {
                        this.player.position.y--;
                        this.flashlightStepControl();
                        this.addMoveLog()
                    }
                    break;
                case 'right':
                    this.player.heading = 1;
                    if (!(this.level.map[mapIndex] & 2)) {
                        this.player.position.x++;
                        this.flashlightStepControl();
                        this.addMoveLog()
                    }
                    break;
                case 'down':
                    this.player.heading = 2;
                    if (!(this.level.map[mapIndex] & 4)) {
                        this.player.position.y++;
                        this.flashlightStepControl();
                        this.addMoveLog()
                    }
                    break;
                case 'left':
                    this.player.heading = 3;
                    if (!(this.level.map[mapIndex] & 8)) {
                        this.player.position.x--;
                        this.flashlightStepControl();
                        this.addMoveLog()

                    }
                    break;
                default:
                    break;
            }
        },
        swipeStart(event) {
            this.touchStartX = event.touches[0].pageX;
            this.touchStartY = event.touches[0].pageY;
        },
        swipeEnd(event) {
            if(!this.swipeFlag && this.obstacleModal){
                return;
            } else if (!this.swipeFlag && !this.obstacleModal) {
                this.$emit('toggleSwiping');
                return;
            }
            this.touchEndX = event.changedTouches[0].pageX;
            this.touchEndY = event.changedTouches[0].pageY;
            this.handleSwipe();
        },
        handleSwipe() {
            const swipeSensitivity = 100;

            if ((this.touchEndX < this.touchStartX) && (Math.abs(this.touchEndX - this.touchStartX) > swipeSensitivity)) {
                this.moveIfPossible('left');
            }
            if ((this.touchEndX > this.touchStartX) && (Math.abs(this.touchEndX - this.touchStartX) > swipeSensitivity)) {
                this.moveIfPossible('right');
            }
            if ((this.touchEndY < this.touchStartY) && (Math.abs(this.touchEndY - this.touchStartY) > swipeSensitivity)) {
                this.moveIfPossible('up');
            }
            if ((this.touchEndY > this.touchStartY) && (Math.abs(this.touchEndY - this.touchStartY) > swipeSensitivity)) {
                this.moveIfPossible('down');
            }
        },
        addMoveLog() {
            this.movementLog.push(`${this.player.position.x}-${this.player.position.y}`)
            this.updateMemory()
        }
    }
}