<template>
  <div class="tile-tile">
    <div :style="`opacity:${1 - darkness}`">
      <img class="tile-floor" alt="game floor texture" :src="require(`@/assets/textures/floors/floor_${textureSeed}.png`)">
      <img v-show="(tileData & 1)" class="tile-wall tile-wall-N" alt="game wall texture" :src="require(`@/assets/textures/walls/wall_${getLevelWall}.png`)" draggable="false">
      <img v-show="(tileData & 2)" class="tile-wall tile-wall-E" alt="game wall texture" :src="require(`@/assets/textures/walls/wall_${getLevelWall}.png`)" draggable="false">
      <img v-show="(tileData & 4)" class="tile-wall tile-wall-S" alt="game wall texture" :src="require(`@/assets/textures/walls/wall_${getLevelWall}.png`)" draggable="false">
      <img v-show="(tileData & 8)" class="tile-wall tile-wall-W" alt="game wall texture" :src="require(`@/assets/textures/walls/wall_${getLevelWall}.png`)" draggable="false">
      <img v-show="(tileData & 16)" class="tile-obstacle" alt="game obstacle texture" src="@/assets/textures/items/obstacle_0.png" draggable="false">
      <img v-show="(tileData & 32)" class="tile-power" alt="game power texture" src="@/assets/textures/items/power_0.png" draggable="false">
    </div>
    <img v-show="(tileData & 64)" class="tile-start" alt="game start texture" src="@/assets/textures/items/start_0.png" draggable="false">
    <img v-show="(tileData & 128)" class="tile-end" alt="game end texture" src="@/assets/textures/items/end_0.png" draggable="false">

    <div v-show="memoryStrength > 0" class="tile-memory-holder">
      <div class="tile-memory-point" :style="`width:${memoryStrength*100}%; height:${memoryStrength*100}%;`"></div>
    </div>

    <div v-show="hintStrength > 0" class="tile-memory-holder">
      <div class="tile-hint-point" :style="`width:${hintStrength*100}%; height:${hintStrength*100}%;`"></div>
    </div>

    <img v-show="this.hasPlayer" class="tile-player" alt="game player texture" src="@/assets/textures/player/player_0.png"
     :style="`transform:rotate(${player.heading * 90}deg)`">
  </div>
</template>

<script>
export default {
  name: 'MazeTile',
  props: ['tile_pos', 'level_data', 'player', 'visibility', 'movements', 'hintSolution', 'updateHint'],
  data () {
    return {
      darkness: Number(process.env.VUE_APP_DEFAULT_DARKNESS),
      hasPlayer: false,
      memoryStrength: 0,
      hintStrength: 0,
      playerPos: {x: undefined, y: undefined},
      textureSeed: 0
    }
  },
  computed: {
    isStart () {
      return (this.tile_pos[0] === this.level_data.start[0]) && (this.tile_pos[1]=== this.level_data.start[1])
    },
    isEnd () {
      return (this.tile_pos[0] === this.level_data.end[0]) && (this.tile_pos[1]=== this.level_data.end[1])
    },
    tileData () {
      return this.level_data.map[(this.tile_pos[1]*this.level_data.gridSize[1] + (this.tile_pos[0]))]
    },
    getLevelWall() {
      switch(this.level_data.gridSize[0]){
        case 5:
          return 0
        case 7:
          return 1
        case 9:
          return 2
        default:
          return 3
      }
    }
  },
  methods: {
    checkForPlayer() {
      this.hasPlayer = (this.tile_pos[0] === this.playerPos.x) && (this.tile_pos[1]=== this.playerPos.y)
      this.calcDarkness()
      this.checkForMemory()
    },
    calcDarkness() {
      if(Math.abs(this.tile_pos[0] - this.playerPos.x) <= this.visibility && Math.abs(this.tile_pos[1] - this.playerPos.y) <= this.visibility){
        
        // how many different shade volumes
        let changeStep = 1/((this.visibility*2) + 1);
        // how far from player `Tile` is
        let stepsDelta = (Math.abs(this.tile_pos[0] - this.playerPos.x)) + (Math.abs(this.tile_pos[1] - this.playerPos.y));
        // calc the darkness for `Tile`
        this.darkness = 0 + (changeStep*1.2)*stepsDelta
      }
      else{
        this.darkness = Number(process.env.VUE_APP_DEFAULT_DARKNESS)
      }
    },
    checkForMemory() {
      this.memoryStrength = 0
      let indexMatch = this.movements.indexOf(`${this.tile_pos[0]}-${this.tile_pos[1]}`)
      if (indexMatch >= 0) {

        if(this.movements.length < Number(process.env.VUE_APP_MOVES_COUNT)){
          this.memoryStrength = ((indexMatch+1) / this.movements.length)*.2
        }
        else{
          this.memoryStrength = ((indexMatch+1) / Number(process.env.VUE_APP_MOVES_COUNT))*.2
        }
      }
    },
    checkForHint() {
      this.hintStrength = 0
      let indexMatch = this.hintSolution.indexOf(`${this.tile_pos[0]}-${this.tile_pos[1]}`)
      if (indexMatch >= 0) {
        if(this.hintSolution.length < Number(process.env.VUE_APP_HINT_COUNT)){
          this.hintStrength = (1.1 - ((indexMatch+1) / this.hintSolution.length))*.4
        }
        else{
          this.hintStrength = (1.1 - ((indexMatch+1) / Number(process.env.VUE_APP_HINT_COUNT)))*.4
        }
      }
    },
  },
  watch: {
    'player.position.x': {
      handler(newValue) {
        this.playerPos.x = newValue
        this.checkForPlayer()
      },
    },
    'player.position.y': {
      handler(newValue) {
        this.playerPos.y = newValue
        this.checkForPlayer()
      },
    },
    visibility: {
      handler() {
        this.calcDarkness()
      },
    },
    updateHint() {
      this.checkForHint()
    },
  },
  created () {
    this.playerPos.x = this.player.position.x
    this.playerPos.y = this.player.position.y
    this.checkForPlayer()
    this.textureSeed = Math.floor(Math.random() * (4 - 0) + 0)
  },
}
</script>

<style>
  .tile-tile{
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    position: relative;
    background-color: var(--color-bg-darkness);
  }
  .tile-tile img,
  .tile-memory-holder{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .tile-wall-E{
    transform: rotate(90deg);
  }
  .tile-wall-S{
    transform: rotate(180deg);
  }
  .tile-wall-W{
    transform: rotate(270deg);
  }
  .tile-player{
    transition: .3s;
  }
  .tile-memory-holder{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tile-memory-point{
    border-radius: 50%;
    background-color: var(--color-item-memory);
    opacity: .4;
    transition: 1.5s;
  }

  .tile-hint-point {
    border-radius: 50%;
    background-color: var(--color-item-hint);
    opacity: .8;
    transition: 1.5s;
  }
</style>