<template>
  <div class="modal-overlay">
    <div class="modal-content container wide-at-print">
      <div class="menu-logo-holder only-at-print">
        <img alt="Vue logo" src="@/assets/textures/graphics/logo.png">
      </div>
      
      <div v-if="this.$props.type == 'info'">
        <h3>Goal of the Game</h3>
        <p>Your crypto mining rig triggered the circuit breaker. You know where the fuse box is, but you have no idea, how to get there. Your mission is to get the power back on, so you can keep getting rich (in a sence of electricity bills). Due to lack of physical activity and/or fresh air, you may feel like your place is different each time. Don't forget it's a mess out there, so be aware of obstacles.</p>

        <h3>Flashlight</h3>
        <p>Thanks to your mom you have a flashlight, which can help you see further. Thanks to you, the flashlight has a terrible battery - the battery will drain by each step you do. Or just by switching it on and off like a maniac.</p>

        <h3>Obstacles</h3>
        <p>All your friends know, you're a lazy person. It is quite possible you'll find some boxes you never threw away, which may block the aisle. Pick up all the boxes, so you can disassemble them with a tool you have.</p>

        <h3>Items</h3>
        <div class="modal-img-note-frame">
          <img src="@/assets/textures/items/power_0.png" alt="power item">
          <p>Pair of batteries that will fully charge your flashlight.</p>
        </div>
        <div class="modal-img-note-frame">
          <img src="@/assets/textures/items/obstacle_0.png" alt="obstacle item">
          <p>Obstacles in aisle - you need to clear all the objects to pass through.</p>
        </div>
        <div class="modal-img-note-frame">
          <img src="@/assets/textures/items/end_0.png" alt="obstacle item">
          <p>The fuse box, your target, is marked with this symbol.</p>
        </div>
        <div class="modal-img-note-frame">
          <img src="@/assets/textures/items/start_0.png" alt="obstacle item">
          <p>This symbol marks the start - in case you want to give up and cry where you started.</p>
        </div>

        <h3>Memory</h3>
        <p>Some amount of your most recent moves are shown by little purple dots. The strength of each memory point is shown by size of the dot.</p>

        <h3>Controls</h3>
        <template v-if="!isMobile">
          <p>Movements are done by either arrows on a keyboard, or keys W/S/A/D.</p>
          <div class="modal-two-img-row">
            <img src="@/assets/controls/controls_arrows.png" alt="controls example of swipes">
            <img src="@/assets/controls/controls_wsad.png" alt="controls example of taps">
          </div>
          <p>To toggle flashlight, either click on the battery or press 'space' key on a keyboard.</p>
          <div class="modal-two-img-row">
            <img src="@/assets/controls/controls_flashlight_desktop_b.png" alt="controls example of flashlight on desktop">
            <img src="@/assets/controls/controls_flashlight_desktop_a.png" alt="controls example of flashlight on desktop">
          </div>
        </template>
        <template v-else-if="isMobile">
          <p>Movements are done by either swipes or taps on maze area:</p>
          <div class="modal-two-img-row">
            <img src="@/assets/controls/controls_swipes.png" alt="controls example of swipes">
            <img src="@/assets/controls/controls_taps.png" alt="controls example of taps">
          </div>
          <p>To toggle flashlight, either click on the battery or shake your mobile device.</p>
          <div class="modal-two-img-row">
            <img src="@/assets/controls/controls_flashlight_mobile_b.png" alt="controls example of flashlight on mobile">
            <img src="@/assets/controls/controls_flashlight_mobile_a.png" alt="controls example of flashlight on mobile">
          </div>
        </template>

        <h3>Disassembling boxes</h3>
        <p>Pick up all boxes and move them to your working area to clear the way:</p>
        <div class="modal-two-img-row">
          <img src="@/assets/controls/controls_draganddrop.png" alt="controls example of drag and drop feature">
        </div>

        <h3>Hints</h3>
        <p>If you need a hint press 'hint' button on a screen to see for a brief moment the way to an exit. There's no shame in need of help in a game for children.</p>

        <p>Good luck!</p>

        <button class="btn-i no-print btn-i-fullW btn-i-primary" @click="closeModal">Close</button>
      </div>

      <div v-else-if="this.$props.type == 'win'" class="no-print">
        <h3>You did it!</h3>
        <div class="modal-win-note">
          <p>{{ getFinishText() }}</p>
        </div>
        <div class="modal-button-holder">
          <button type="button" class="btn-i-w btn-i-w-secondary" @click="exitToMenu">Exit to Menu</button>
          <button type="button" class="btn-i-w btn-i-w-primary" @click="nextLevel">Next Level</button>
        </div>
      </div>

      <div v-else-if="this.$props.type == 'levels'" class="level-list-holder">
        <h3>List of levels</h3>
        <p>Click to play level again</p>
        <div>
          <template v-for="levelType in Object.keys(levelList)">
            <h4 :key="`type-${levelType}`">{{ levelType + ':' }}</h4>
            <div :key="`hold-${levelType}`">

              <template v-for="(level, i) in levelList[levelType]">
                <button type="button" class="btn-i btn-list" :key="`lvl-${levelType}-${level}-${i}`" @click="runSpecificLevel(levelType, i)">
                  {{ `level #${i+1}`}}
                </button>
              </template>

            </div>
          </template>
        </div>
        <div class="no-print modal-level-list-holder">
          <button type="button" class="btn-i btn-i-w-secondary" @click="resetGameProgress">Reset Game</button>
          <button type="button" class="btn-i btn-i-w-primary" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalLayer',
  props: {
    type: String,
  },
  data() {
    return {
      levelList: {}
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    nextLevel() {
      if (this.gameFinished()) {
        this.exitToMenu()
      }
      else {
        this.closeModal();
        this.$emit('loadNextLevel');
      }
    },
    exitToMenu(){
      this.closeModal();
      this.$emit('exitToMenu');
    },
    resetGameProgress() {
      let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
      let levelTypes = Object.keys(progressLog)
      
      for(let type in levelTypes){
        if(levelTypes[type] === 'intro'){
          continue
        }
        for(let level in progressLog[levelTypes[type]]){
          progressLog[levelTypes[type]][level] = 0
        }
      }

      localStorage.setItem('levelsProgress', JSON.stringify(progressLog))
      this.$emit('closeModal')
    },
    runSpecificLevel(type, index) {
      let levelsOrder = JSON.parse(localStorage.getItem('levelsOrder'))
      let targetIndex = 0
      
      // find index of level (in randomized array)
      for (let i in levelsOrder[type]) {
        if (levelsOrder[type][i] === index){
          targetIndex = i
        }
      }


      let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
      progressLog[type][targetIndex] = 0
      localStorage.setItem('levelsProgress', JSON.stringify(progressLog))
      this.$emit('runGame')
    },
    gameFinished() {
        let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
        let levelTypes = Object.keys(progressLog)

        for(let type in levelTypes){
          for(let level in progressLog[levelTypes[type]]){
            if(progressLog[levelTypes[type]][level] !== 2){
              return false
            }
          }
        }

        return true
    },
    getFinishText() {
      let lines = [ "Are you sure you know how to play this game?", "I thought you got lost.", "That wasn't that bad...", 
      "What took you so long...", "Good job.", "*eyeroll*", "You can rest now.", "I'm proud of you.", "*sarcastic clapping*" ]
      let seed = Math.floor(Math.random() * lines.length)
      return lines[seed]
    }
  },
  computed: {
    isMobile() {
      if(window.innerWidth <= 992){
        return true
      }
      return false
    },
  },
  created() {
    this.levelList = JSON.parse(localStorage.getItem('levelsProgress'))
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000da;
}

.modal-content {
  background-color: white;
  border-radius: 1rem;
  overflow-y: auto;
  padding: 2.5rem clamp(.3rem, 8vw, 6rem);
  max-width: 700px;
  max-height: 90vh;
  text-align: justify;
  height: fit-content;
}

h2, h3{
  margin-top: .8rem;
  font-size: 1.7rem;
  text-align: center;
  color: var(--color-bg-1);
  border-bottom: 2px solid var(--color-text-primary-glare);
}

h4 {
  margin-top: 1.5rem;
}

.modal-img-note-frame {
  display: flex;
}

.modal-img-note-frame p {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  width: 78%;
}

.modal-img-note-frame img {
  width: 22%;
  max-width: 5rem;
  height: auto;
  object-fit: contain;
}

.modal-two-img-row {
  display: flex;
  justify-content: center;
}

.modal-two-img-row img {
  width: 50%;
  max-width: 17rem;
  height: auto;
}

.modal-button-holder {
  display: flex;
  justify-content: center;
  margin-top: 1.2rem;
}

.modal-win-note {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.modal-level-list-holder {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.level-list-holder p{
  text-align: center;
}

</style>