<template>
  <div id="app">
    <div class="container">
      <AppGame v-if="displayGame" @switchMenuGame="toggleGameComponent"/>
      <AppMenu v-else @switchMenuGame="toggleGameComponent"/>
    </div>
  </div>
</template>

<script>
import AppGame from './components/AppGame.vue';
import AppMenu from './components/AppMenu.vue';
import levelsData from '@/data/levels.json'

export default {
  name: 'App',
  components: {
    AppGame,
    AppMenu,
  },
  data() {
    return {
      displayGame: 0,
      levelsData: levelsData
    }
  },
  methods:{
    toggleGameComponent() {
      this.displayGame = !this.displayGame;
    },
    initLocalStorage() {
      if(!localStorage.getItem('inited')){
        
        this.initLevelOrder()
        this.initLevelState()

        localStorage.setItem('inited','true')
      }
    },
    initLevelOrder() {
      let obj = {intro:[0], easy:[], hard:[], hehe:[]}
      for(let level in this.levelsData.easy){
        obj.easy.push(Number(level))
      }
      for(let level in this.levelsData.hard){
        obj.hard.push(Number(level))
      }
      for(let level in this.levelsData.hehe){
        obj.hehe.push(Number(level))
      }

      this.shuffle(obj.easy)
      this.shuffle(obj.hard)
      localStorage.setItem('levelsOrder', JSON.stringify(obj))
    },
    initLevelState() {
      let obj = {intro:[0], easy:[], hard:[], hehe:[]}
      for(let level in this.levelsData.easy){
        obj.easy[level] = 0
      }
      for(let level in this.levelsData.hard){
        obj.hard[level] = 0
      }
      for(let level in this.levelsData.hehe){
        obj.hehe[level] = 0
      }

      localStorage.setItem('levelsProgress', JSON.stringify(obj))
    },
    shuffle(array) {
      array.sort(() => Math.random() - 0.5);
    }
  }, 
  created() {
    document.title = process.env.VUE_APP_TITLE
    this.initLocalStorage()
  },
}
</script>

<style>
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-top: .7rem;
}
</style>
