<template>
  <div id="tile-wrapper">
    <div class="gameboard-wrapper">
      <div v-if="level" class="gameboard-content-holder">

        <div v-for="row in level.gridSize[1]" :key="`row-${row}`" class="tile-row">
          <MazeTile v-for="col in level.gridSize[0]" :key="`tile-${row-1}-${col-1}`" :tile_pos="[col - 1, row - 1]"
            :level_data="level" :player="player" :visibility="visibilityRadius" :movements="movementMemory" 
            :hintSolution="hintSolution" :updateHint="updateHint"/>
        </div>

        <div class="gameboard-moves-holder">
          <div class="gameboard-move-btn" @click="moveIfPossible('up')"></div>
          <div class="gameboard-move-btn" @click="moveIfPossible('right')"></div>
          <div class="gameboard-move-btn" @click="moveIfPossible('left')"></div>
          <div class="gameboard-move-btn" @click="moveIfPossible('down')"></div>
        </div>
      </div>
    </div>

    <FlashlightStatus :energy="player.flashlightEnergy" @flashlightClick="flashlightOnOff"/>
  </div>
</template>

<script>
import MazeTile from './MazeTile.vue';
import FlashlightStatus from './FlashlightStatus.vue';
import levelsData from '@/data/levels.json';
import playerMovement from '@/logic/player-movement';
import Vue from 'vue';

export default {
  extends: playerMovement,
  name: 'GameContent',
  components: {
    MazeTile,
    FlashlightStatus,
  },
  props: ['loadLevel', 'showHint', 'swipeFlag', 'activeKeysFlag', 'obstacleModal'],
  data() {
    return {
      levelsData: levelsData,
      level: {},
      player: {
        position: { x: undefined, y: undefined },
        heading: process.env.VUE_APP_INIT_PLAYER_HEADING,
        flashlightActive: false,
        flashlightEnergy: Number(process.env.VUE_APP_INIT_FLASHLIGHT_ENERGY),
      },
      visibilityRadius: Number(process.env.VUE_APP_INIT_VISIBLE_RADIUS),
      touchStartX: 0,
      touchStartY: 0,
      touchEndX: 0,
      touchEndY: 0,
      movementLog: [],
      movementMemory: [],
      movementMemoryCount: Number(process.env.VUE_APP_MOVE_MEMORY_COUNT),
      triggeredBeforeCooldown: false,
      hintSolution: [],
      updateHint: false,
    }
  },
  created() {
    this.loadCurrentLevel()    
  },
  methods: {
    handleKeydown(event) {
      switch (event.keyCode) {
        case 38:
        case 87:
          this.moveIfPossible('up');
          break;
        case 39:
        case 68:
          this.moveIfPossible('right');
          break;
        case 40:
        case 83:
          this.moveIfPossible('down');
          break;
        case 37:
        case 65:
          this.moveIfPossible('left');
          break;
        case 32:
          this.flashlightOnOff();
          break;
        default:
          break;
      }
    },
    handleShake(event) {
      const shakeSensitivity = 20;

      if ((Math.abs(event.acceleration.x) > shakeSensitivity) 
      || (Math.abs(event.acceleration.y) > shakeSensitivity) 
      || (Math.abs(event.acceleration.z) > shakeSensitivity)) {
        this.triggerFlashlightOnce()
      }
    },
    triggerFlashlightOnce() {
      if ( !this.triggeredBeforeCooldown ) {
        this.triggeredBeforeCooldown = true
        this.flashlightOnOff()
        setTimeout(() => {this.triggeredBeforeCooldown = false}, Number(process.env.VUE_APP_GYRO_COOLDOWN))
      }
    },
    flashlightOnOff() {
      if (this.player.flashlightActive) {
        this.player.flashlightActive = false;
        this.player.flashlightEnergy--;

        if(this.player.flashlightEnergy < 0){
          this.player.flashlightEnergy = 0
        }

        this.visibilityRadius = Number(process.env.VUE_APP_INIT_VISIBLE_RADIUS)
        return;
      }
      if (!this.player.flashlightEnergy) {
        return;
      }
      this.player.flashlightActive = true;
      this.visibilityRadius = Number(process.env.VUE_APP_FLASH_VISIBLE_RADIUS)
    },
    flashlightStepControl() {
      if (this.player.flashlightActive && this.player.flashlightEnergy) {
        this.player.flashlightEnergy--;

        if(this.player.flashlightEnergy < 0){
          this.player.flashlightEnergy = 0
        }
      }
      if (!this.player.flashlightEnergy) {
        this.visibilityRadius = Number(process.env.VUE_APP_INIT_VISIBLE_RADIUS)
        this.player.flashlightActive = false;
      }
    },
    loadCurrentLevel() {
      let targetLevel = this.findCurrentLevel()

      this.level = JSON.parse(JSON.stringify(this.levelsData[targetLevel.type][targetLevel.level]))
      this.player.position.x = this.level.start[0]
      this.player.position.y = this.level.start[1]
      this.player.flashlightActive = false
      this.player.flashlightEnergy = Number(process.env.VUE_APP_INIT_FLASHLIGHT_ENERGY)

      // check if 'game-in-progress'
      let levelsProgress = JSON.parse(localStorage.getItem('levelsProgress'))
      if(Number(levelsProgress[targetLevel.type][targetLevel.index]) === 1){
        
        // load stored game
        let progress = JSON.parse(localStorage.getItem('gameplayCache'))
        this.player = progress.player
        this.visibilityRadius = progress.visibilityRadius
        this.movementLog = progress.movementLog
        this.movementMemory = progress.movementMemory
        this.movementMemoryCount = progress.movementMemoryCount
        this.flashlightActive = progress.flashlightActive
        this.flashlightEnergy = progress.flashlightEnergy
        this.level.map = progress.map
      }
    },
    findCurrentLevel() {
      let progressLog = JSON.parse(localStorage.getItem('levelsProgress'))
      let orderLog = JSON.parse(localStorage.getItem('levelsOrder'))
      let levelTypes = Object.keys(progressLog)

      for(let type in levelTypes){
        for(let level in progressLog[levelTypes[type]]){
          
          // find first not-finished / not-played game
          if(progressLog[levelTypes[type]][level] !== 2){

            return {type: levelTypes[type], level: orderLog[levelTypes[type]][level], index: level}
          }
        }
      }
    },
    updateMemory() {
      let startIndex = this.movementLog.length - this.movementMemoryCount
      if(startIndex < 0){
        startIndex = 0
      }
      this.movementMemory = this.movementLog.slice(startIndex)
    },
    saveGameProgress() {
      // check if player's not at the end
      if(this.player.position.x === this.level.end[0] && this.player.position.y === this.level.end[1]){
        return
      }

      let targetLevel = this.findCurrentLevel()
      let levelsProgress = JSON.parse(localStorage.getItem('levelsProgress'))

      // set level as `started, not finished`
      levelsProgress[targetLevel.type][targetLevel.index] = 1
      localStorage.setItem('levelsProgress', JSON.stringify(levelsProgress))

      // store in-game progress
      let progress = {
        player: this.player,
        visibilityRadius: this.visibilityRadius,
        movementLog: this.movementLog,
        movementMemory: this.movementMemory,
        movementMemoryCount: this.movementMemoryCount,
        flashlightActive: this.flashlightActive,
        flashlightEnergy: this.flashlightEnergy,
        map: this.level.map,
      }
      localStorage.setItem('gameplayCache', JSON.stringify(progress))
    },
    checkForActions(position) {

      // END
      if(position.x === this.level.end[0] && position.y === this.level.end[1]){
        
        // set level as done
        let targetLevel = this.findCurrentLevel()
        let levelsProgress = JSON.parse(localStorage.getItem('levelsProgress'))

        levelsProgress[targetLevel.type][targetLevel.index] = 2
        localStorage.setItem('levelsProgress', JSON.stringify(levelsProgress))

        this.visibilityRadius = 200
        
        setTimeout(() => {
          this.$emit('finishLevel')
          this.visibilityRadius = Number(process.env.VUE_APP_INIT_VISIBLE_RADIUS)
        }, 1500)

        return
      }

      // POWER
      for (let i = 0; i < this.level.power.length; i++) {
        let targetPos = this.level.power[i];

        if (targetPos[0] === position.x && targetPos[1] === position.y) {
          if(this.level.map[( position.y * this.level.gridSize[1]) + position.x ] & 1<<process.env.VUE_APP_POWER_BIT){
            this.level.map[( position.y * this.level.gridSize[1]) + position.x ] -= 1<<process.env.VUE_APP_POWER_BIT

            this.player.flashlightEnergy += Number(process.env.VUE_APP_POWER_ADD)
            if(this.player.flashlightEnergy > Number(process.env.VUE_APP_POWER_ADD)) {
              this.player.flashlightEnergy = Number(process.env.VUE_APP_POWER_ADD)
            }
            return
          }
        }
      }

      // OBSTACLE
      for (let i = 0; i < this.level.obstacle.length; i++) {
        let targetPos = this.level.obstacle[i];

        if (targetPos[0] === position.x && targetPos[1] === position.y) {
          if(this.level.map[( position.y * this.level.gridSize[1]) + position.x ] & 1<<process.env.VUE_APP_OBSTACLE_BIT){
            this.level.map[( position.y * this.level.gridSize[1]) + position.x ] -= 1<<process.env.VUE_APP_OBSTACLE_BIT

            this.$emit('toggleObstacleModal')
            this.$emit('toggleSwiping')
            this.$emit('toggleActiveKeys')

            return
          }
        }
      }
    },
    calcHintSolution() {
      let indexOnCorrectPath = this.level.solution.indexOf(`${this.player.position.x}-${this.player.position.y}`)
      
      if (indexOnCorrectPath >= 0) {
        // player on correct path
        this.hintSolution = this.level.solution.slice(indexOnCorrectPath)
      }
      else {
        // player away from correct path

        // * from end of solution find first MATCH with history of player's movement
        for(let i = this.level.solution.length; i > 0; i--){
          var lastCorrectIndexInLog = this.movementLog.indexOf(this.level.solution[i])
          if (lastCorrectIndexInLog >= 0) {
            indexOnCorrectPath = i
            break
          }
        }

        // * cut path behind player (when player is returning on the hint path)
        let logFromCorrectPoint = this.movementLog.slice(lastCorrectIndexInLog)
        let backIndex = logFromCorrectPoint.indexOf(`${this.player.position.x}-${this.player.position.y}`)

        // * glue together `fix path` + `correct path` 
        this.hintSolution = logFromCorrectPoint.slice(0, backIndex).reverse()
        this.hintSolution = this.hintSolution.concat(this.level.solution.slice(indexOnCorrectPath+1))
      }

    },
  },
  watch: {
    'player.position': {
      handler(newPos){
        this.checkForActions(newPos)
      },
      deep: true,
    },
    loadLevel(newValue) {

      // request of new level load (when true)
      if(newValue){
        this.level = undefined
        this.movementLog = []
        this.movementMemory = []

        Vue.nextTick(() => {
          // load next level
          // with nextTick so the map re-renders properly
          this.loadCurrentLevel()
        })
      }
    },
    showHint(doShow) {
      if (doShow) {
        this.calcHintSolution()
      }
      else {
        this.hintSolution = []
      }
      this.updateHint = true;
      setTimeout(() => {this.updateHint = false}, 100)
    },
    'obstacleModal': {
      handler(newValue){
        if(!newValue){
          this.$emit('toggleActiveKeys');
        }
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
    window.addEventListener("touchstart", this.swipeStart, false);
    window.addEventListener("touchend", this.swipeEnd, false);
    this.initGyro();
  },
  beforeDestroy() {
    this.saveGameProgress()
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeydown);
    window.removeEventListener("touchstart", this.swipeStart, false);
    window.removeEventListener("touchend", this.swipeEnd, false);
    this.removeGyro();
  }
}
</script>

<style>
.tile-row {
  display: flex;
}

#tile-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  position: relative;
}

.gameboard-wrapper {
  width: 100%;
  margin: min(1.5rem, 2vh) auto;
}

.gameboard-content-holder {
  position: relative;
  margin: 0 auto;
  max-height: 70vh;
  width: auto;
  height: auto;
  aspect-ratio: 1;
  background-color: var(--color-bg-darkness);
  border: 4px solid var(--color-map-border);
}

.gameboard-moves-holder {
  position: absolute;
  display: grid;
  grid-template-columns: 2fr 2fr;
  transform: rotate(45deg);
  width: 120%;
  top: -10%;
  left: -10%;
}

.gameboard-move-btn {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}
</style>