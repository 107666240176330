<template>
  <div class="flashlight-wrapper" @click="$emit('flashlightClick')">
    <div class="flashlight-tile-holder">
      <template v-for="power in maxPower">
        <div :key="power" :class="energyClass(power)"></div>
      </template>
    </div>
  </div>
</template>

<script>

const ENERGY_ON_CLASS = 'flashlight-tile-on'
const ENERGY_OFF_CLASS = 'flashlight-tile-off'

export default {
  props: ['energy'],
  name: 'FlashlightStatus',
  data() {
    return {
      maxPower: Number(process.env.VUE_APP_FLASHLIGHT_MAX)
    }
  },
  methods: {
    energyClass(index) {
      return (index <= this.energy) ? ENERGY_ON_CLASS : ENERGY_OFF_CLASS
    },
  }
}
</script>

<style>
.flashlight-wrapper {
  z-index: 20;
  
  margin: min(1.5rem, 2vh) auto;
  border: 4px solid var(--color-text-primary);
  border-radius: 1rem;
}

.flashlight-tile-holder {
  position: relative;
  display: flex;
  justify-content: center;
  position: relative;
  padding: .4rem .6rem;
}
.flashlight-tile-holder::after{
  content: '';
  width: 12px;
  height: 50%;
  position: absolute;
  right: -13px;
  border-radius: 5px;
  top: 26%;
  background-color: var(--color-text-primary);
}

.flashlight-tile-on,
.flashlight-tile-off {
  width: 20px;
  height: 50px;
  margin: 1px;
  border-radius: 4px;
}

.flashlight-tile-on {
  background-color: var(--color-text-primary-glare);
}
.flashlight-tile-off {
  background-color: dimgrey;
}

</style>