
export default {
methods: {
    initGyro(){
      
      if (window.DeviceMotionEvent == null) {
        //  not supported
        return
      }
      else if (DeviceMotionEvent.requestPermission) {
        // iOS needs permission
        this.setupForPermission()
        this.askForPermission()        
      }
      else{
        window.addEventListener('devicemotion', (e) => {this.handleShake(e)})
      }
    },
    removeGyro() {
      window.removeEventListener('devicemotion', (e) => {this.handleShake(e)})
    },
    setupForPermission() {
      // permission can be asked only after users' input (e.g. click) - not
      // automatically, hence the touch listener
      window.addEventListener('click', this.askForPermission)
      window.addEventListener('touchend', this.askForPermission)

    },
    askForPermission() {
      DeviceMotionEvent.requestPermission().then((state) => {
        if(state === 'granted'){
          window.addEventListener('devicemotion', (e) => {this.handleShake(e)})
        }
        window.removeEventListener('click', this.askForPermission)
        window.removeEventListener('touchend', this.askForPermission)
      })
    },
  },
}
